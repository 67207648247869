/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Row, Col, Container } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"

const LayoutML = ({ children }) => {
  const data = useStaticQuery(graphql`
      query {
          image: file(relativePath: { eq: "ml-legal.png" }) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  return (
    <>
      <Helmet>
        <body className="ml-legal" />
      </Helmet>
      <Container fluid className={"py-4 py-md-5 bg-black"}>
        <Row>
          <Col className={"p-0"}>
            <Img fluid={data.image.childImageSharp.fluid} style={{ maxWidth: `320px`, width: `40vw` }} className={"ml-5"} />
          </Col>
        </Row>
      </Container>
      <div>
        <main>{children}</main>
      </div>
      <Container fluid className="bg-black text-white">
        <Row>
          <Col md={1}></Col>
          <Col className={"mx-3 my-5 p-0 ml-legal-footer"}>
            <Img fluid={data.image.childImageSharp.fluid} style={{ maxWidth: `180px` }} className={"mb-3"} />
             <p className="ragione-sociale">
                <strong>MARCOTULLI, LUKŠIĆ KOKIĆ &amp; PARTNERI O.D. d.o.o.</strong><br/>
                Strossmayerova 16<br/>
                51000 Rijeka - Hrvatska<br/>
                OIB 83733961468
              </p>
          </Col>
        </Row>
      </Container>
    </>
  )
}

LayoutML.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutML
